import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };
  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='SECURING AFFORDABLE INSURANCE IN MISSISSIPPI' activeCard='mississippi' image={'/Mississippi_BG.jpg'}>
          <p>
            Insurance in Mississippi has experienced a lot of turmoil for 2017 open enrollment. Rate increases are up almost 70%. Blue Cross and Blue Shield of
            Mississippi has removed themselves from providing coverage on the exchange in 2017. Options have dwindled and rates have shot up. Not a very good
            recipe for success. At Apollo, we thrive in chaotic conditions.
          </p>
          <p>
            Citizens of Mississippi need us more in 2020 than they have ever needed us before. There are a few affordable options, some on the exchange, and
            some off the exchange. We like to start by asking potential clients a few questions to better understand how they use healthcare, who needs
            coverage, what prescriptions need to be covered, what doctors they want to make sure are available as in-network providers on their new plan, and
            most importantly, what price we need to stay under so the benefit plan fits within their budget. We take this information and advise the type of
            insurance you need: individual insurance, group insurance, life insurance or Medicare supplemental insurance.
          </p>
          <p>Maximize the Value of Your Health Insurance</p>

          <span>EXPERT ADVICE THAT ADDRESSES EVERY NEED</span>

          <p>
            We provide expert advice on how to create a health insurance solution which addresses their needs and stays within budget. We educate our clients on
            how to use their health insurance. Apollo is licensed in the State of Kentucky and appointed with all major insurance companies who provide coverage
            in the state. Our products span, health insurance, Medicare supplements, dental, life insurance, and dental products. Some of our providers include
            Medica, Aetna, Transamerica, Cigna and this is just a sampling of the providers we have to draw solutions from for consumers living in Kentucky .
          </p>

          <p>
            Our desire is to make sure we meet or exceed our client’s expectations when configuring the health insurance for them and their families. We have
            experts waiting to answer any questions you may have about provider networks, prescription coverage, the marketplace, off-exchange health insurance
            products, Medicare, dental or life insurance-centric questions.
          </p>

          <span>EXPERT ADVICE THAT ADDRESSES EVERY NEED</span>

          <p>
            We provide expert advice on how to create a health insurance solution which addresses their needs and stays within budget. We educate our clients on
            how to use their health insurance. Apollo is licensed in the State of Mississippi and appointed with all major insurance companies who provide
            coverage in the state. Our products span, health insurance, Medicare supplements, dental, life insurance, and dental products. Some of our providers
            include Medica, Aetna, Transamerica, Cigna and this is just a sampling of the providers we have to draw solutions from for consumers living in
            Mississippi .
          </p>

          <p>
            Our desire is to make sure we meet or exceed our client’s expectations when configuring the health insurance for them and their families. We have
            experts waiting to answer any questions you may have about provider networks, prescription coverage, the marketplace, off-exchange health insurance
            products, Medicare, dental or life insurance-centric questions.
          </p>
        </Content>
      </div>
    </Layout>
  );
};
